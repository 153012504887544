import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Products = () => {
  return (
    <Container className="my-5">
      <Row className="text-center mb-4">
        <Col>
          <h1>Our Products</h1>
          <p className="lead text-muted">Explore the StrideCal suite of products designed to empower individuals, teams, and enterprises to achieve their highest productivity potential.</p>
        </Col>
      </Row>
      <Row>
        {/* StrideCal Preppy */}
        <Col md={6} lg={3} className="p-3">
          <div className="h-100 border p-4 bg-white shadow-sm rounded">
            <h3 className="text-dark font-weight-bold">StrideCal Preppy</h3>
            <p className="text-muted">
              Preppy helps students prioritize multiple academic and personal commitments, ensuring they stay focused and balanced in a fast-paced environment.
            </p>
            <Link to="/ExplainerVideo" className="btn btn-primary mt-3">watch video &gt;&gt; </Link>
          </div>
        </Col>

        {/* StrideCal Neuronimble */}
        <Col md={6} lg={3} className="p-3">
          <div className="h-100 border p-4 bg-white shadow-sm rounded">
            <h3 className="text-dark font-weight-bold">StrideCal Neuronimble</h3>
            <p className="text-muted">
              Neuronimble empowers individuals with ADHD, early Alzheimer’s, and dementia by offering a personalized, AI-driven focus tool to improve cognitive performance.
            </p>
          </div>
        </Col>

        {/* StrideCal Liquimov */}
        <Col md={6} lg={3} className="p-3">
          <div className="h-100 border p-4 bg-white shadow-sm rounded">
            <h3 className="text-dark font-weight-bold">StrideCal Liquimov</h3>
            <p className="text-muted">
              Liquimov is your personal digital organizer and automated planner, designed for busy executives to manage their time and tasks efficiently.
            </p>
          </div>
        </Col>

        {/* StrideCal Enterprise */}
        <Col md={6} lg={3} className="p-3">
          <div className="h-100 border p-4 bg-white shadow-sm rounded">
            <h3 className="text-dark font-weight-bold">StrideCal Enterprise</h3>
            <p className="text-muted">
              A complete solution for enterprises to promote work-life balance and well-being for employees, while maximizing productivity and delivering transparent data-driven insights.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Products;